import { useApi } from '@tenant/composables'

export type DepartmentsState = {
    departments: string[]
}

export default {
    state: (): DepartmentsState => ({
        departments: [],
    }),

    mutations: {
        setDepartments(state: DepartmentsState, departments: []) {
            state.departments = departments
        },
    },

    getters: {
        departments(state: DepartmentsState) {
            return state.departments
        },
    },

    actions: {
        fetchDepartments({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/departments', 'GET')

            execute().then((response) => {
                commit('setDepartments', response.data)
            })
        },
    },
}
